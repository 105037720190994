import React, { Component, setGlobal } from "reactn";
import { Save_Value } from "../functions/Data";
import * as Ion from "@ionic/react";
import moment from "moment";
import PosStart from "./PosStart";
import LandingPage from "./LandingPage";

let _RvcCode;

export default class InitPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Data: null,
      IsSuccess: 0,
      IsLoading: true,
      IsShowAlert: false,
      ErrorMessage: "Unable to connect Remote Server, try again later !!!",
    };

    //  console.log("InitPage Constructor");
  }

  componentDidMount() {
    Save_Value("INIT");
    Save_Value("DEFAULT");

    if (this.global.ServiceCode === "P") {
      this.setGlobal({
        LiveServiceType: "P",
        LiveServiceTypeName: "Pre-Order",
      });
    }
    if (this.global.ServiceCode === "D") {
      this.setGlobal({ LiveServiceType: "D", LiveServiceTypeName: "Dine In" });
    }

    this._FetchData();

    //console.log("InitPage Did Mount", this.global);
  }

  _FetchData = async () => {
    try {
      let url =
        this.global.BaseUrl +
        "Validate/ValidateProfile?zProfileCode=" +
        this.global.ProfileCode +
        "&zServiceCode=" +
        this.global.ServiceCode +
        "&Token=" +
        this.global.Token +
        "&zTableCode=" +
        this.global.TableCode;

      //     console.log(url);
      var requestOptions = { method: "POST" };

      await fetch(url, requestOptions)
        .then((response) => response.json())

        .then((result) => {
          if (result.IsSuccess === 0) {
            this.setState({
              Data: null,
              IsShowAlert: true,
              IsLoading: false,
              ErrorMessage: result.ErrorMessage,
            });
          } else {
            //      let _ImageLoop
            //     _ImageLoop.push(result.ImageLoop)

            let _date = moment().format("YYYY-MM-DD");
            let _time = moment().format("hh:mm");

            _RvcCode = result.RvcCode;

            let _AppInfo = {
              AppType: this.global.AppType,
              SelectedServiceCode: "S",
              SelectedServiceName: "",
              RvcCode: result.RvcCode,
              OutletCode: result.OutletCode,
              OutletName: result.OutletName,
              TableCode: result.TableCode,
              TableName: result.TableName,
              SelectedDate: _date,
              SelectedTime: _time,
            };

            let _EditQty = false;

            let _RefDesc = result.RefOutletService;

        //    console.log("object", _RefDesc);
            if (_RefDesc.substr(12, 1) === "O") {
              _EditQty = true;
            }

            setGlobal({
              RefDesc: result.RefOutletService,
              IsNoEditQty: _EditQty,
              DefaultAppInfo: _AppInfo,
              WebPaymentInfo: result.WebPaymentInfo,
              OutletCode: result.OutletCode,
              OutletName: result.OutletName,
              CompanyCode: result.CompanyCode,
              CompanyName: result.CompanyName,
              RvcCode: result.RvcCode,
              PriceGroupNo: result.PriceGroupNo,
              BizDate: result.BizDate,
              ImageLoop: result.ImageLoop,
              TableName: result.TableName,
              Color: result.CustomMobileTheme,
            });

            this.setState({
              Data: result,
              IsShowAlert: false,
              IsLoading: false,
              ErrorMessage: result.ErrorMessage,
              IsSuccess: result.IsSuccess,
            });
          }
        });
    } catch (error) {
      this.setState({ Data: null, IsShowAlert: true, IsLoading: false });
    }
  };

  _RenderPage = () => {
   //   console.log('object', this.global)

    if (this.state.IsSuccess === 0) {
      return null;
    }

    /*    if (this.global.ActionType === "ACCEPT") {
         return <PosWebStart />;
       } else {
         return <PosWebStart />;
       } */

    //  if (this.global.ActionType === "ACCEPT") {
    return <LandingPage />;
    //  }
  };

  render() {
    if (this.state.IsLoading === true) {
      return (
        <Ion.IonAlert
          isOpen={this.state.IsLoading}
          onDidDismiss={() => this.setState({ IsLoading: false })}
          message="Please wait while Loading..."
        />
      );
    }

    return (
      <Ion.IonApp>
        <Ion.IonContent fullscreen>
          <Ion.IonAlert
            isOpen={this.state.IsShowAlert}
            onDidDismiss={() => this.setState({ IsShowAlert: false })}
            header={"Alert"}
            message={this.state.ErrorMessage}
            buttons={["OK"]}
          />
        </Ion.IonContent>
        {this._RenderPage()}
      </Ion.IonApp>
    );
  }
}

import React, { Component } from "reactn";
import * as Ion from "@ionic/react";
import { arrowBack, refreshCircle, chevronBack } from "ionicons/icons";
import "./StyleCard.css";
import PosMain from "./PosMain";
import PosStart from "./PosStart";

export default class Message extends Component {

    constructor(props) {
        super(props)

        this.state = {
            IsBakc: false
        }
    }




    render() {

        if (this.state.IsBack === true) {
            return <PosStart />
        }



        return (
            <Ion.IonAlert
                isOpen={true}
                onDidDismiss={() => this.setState({ IsBack: true })}
                message={this.global.LanguagePack[this.global.LanguageNo].AlertNoMessage}
                header={this.global.LanguagePack[this.global.LanguageNo].AlertTitle}
                buttons={["OK"]}
            />

        )
    }
}

import React, { Component, setGlobal } from "reactn";
import { caretForward, arrowBack, cart, trashBin, list, grid, person, menu, chevronBack, checkmark, caretBack, caretForwardCircle } from "ionicons/icons";
import { Save_Value } from "../functions/Data";
import * as Ion from "@ionic/react";
import interface_logo from "../Image/interface_logo.png";
import moment from "moment";
import PosMain from "./PosMain";
import PosStart from "./PosStart";
import { transform } from "lodash";

let IsTimerStarted = false
let _SubmitCount = 0
let _IsRetry = false

export default class PosSettlement extends Component {

    constructor(props) {
        super(props);

        this.state = {
            SubmitCount: 0,
            count: 0,
            IsRetry: false,
            IsFinalExit: false,
            IsTimerStarted: false,
            IsDelay: false,
            IsSuccess: false,
            IsComplete: false,
            IsProcess: true,
            IsAlertError: false,
            IsErrorBack: false,
            AlertMessage: this.global.LanguagePack[this.global.LanguageNo].AlertWait,
            IsBack: false,
            IsSubmit: 0,
            PaymentType: this.props.PaymentType,
            TransID: this.props.TransID,
            GuID: this.props.GuID,
            OrderNo: this.props.OrderNo,
            MrSpeedyToken: null,
            IsToken: false,
            OrderId: null,
            OrderNo: null,
            DisplayTitle: this.global.LanguagePack[this.global.LanguageNo].LabelText1
        };
    }

    _FetchSavePaymentData = () => {
        try {
            let url =
                this.global.BaseUrl +
                "Trans/StoreData?zOutletCode=" +
                this.global.OutletCode;
            let NewData;
            let NewArray = [];

            let _NewOutlet = this.global.LiveCollectOutletCode;
            switch (this.global.ServiceCode) {
                case ("P", "D"):
                    _NewOutlet = this.global.OutletCode;
                    break;
                default:
                    _NewOutlet = this.global.LiveCollectOutletCode;
                    break;
            }

            let _SerCode = this.global.ServiceCode
            if (this.global.LiveServiceType === "T") {
                _SerCode = 'D'
            }


            if (this.global.ServiceCode === 'T') {
                _SerCode = 'T'
            }



            let _SaveData = {
                DelCharge: 0,
                PayOrderNo: this.state.OrderNo,
                PayGuID: this.state.GuID,
                PayTransID: this.state.TransID,
                MemberCode: this.global.MemberCode,
                MemberName: this.global.MemberName,
                //OutletCode: this.global.OutletCode,
                OutletCode: _NewOutlet,
                ServiceCode: _SerCode,
                TableCode: this.global.TableCode,
                Token: this.global.Token,
                WebRemark: this.global.LiveRemark,
                WebPaymentCode: this.global.LivePaymentCode,
                WebPaymentName: this.global.LivePaymentName,
                WebServiceCode: this.global.LiveServiceType,
                Qty: this.global.LiveItemCount,
                Subtotal: this.global.LiveSubtotal,
                TaxAmt: this.global.LiveTaxValue,
                SvcAmt: this.global.LiveSvcValue,
                Rounded: this.global.LiveRounded,
                NettAmt: this.global.LiveAmount,
                items: this.global.LiveData,
                DeliveryAddress: this.global.LiveDeliveryAddress,
                CollectionOutletCode: this.global.LiveCollectOutletCode,
                CollectionOutletName: this.global.LiveCollectOutletName,
                CollectionAddress: this.global.LiveCollectOutletAddress,
            };

            NewArray.push(_SaveData);
            NewData = JSON.stringify(NewArray);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: NewData,
                redirect: "follow",
            };

          //     console.log('Submit Data -- ', NewData)
         //      console.log('Submit url --', url)
            fetch(url, requestOptions)
                .then((response) => response.json())

                .then((result) => {

                    //      console.log('result', result)
                    if (result.IsSuccess === 0) {
                        _SubmitCount = _SubmitCount + 1
                        _IsRetry = false
                        this.setState({
                            IsSuccess: false,
                            IsComplete: false,
                            IsRetry: true,
                            IsSubmit: 9,
                            IsDelay: false,
                            IsAlertError: true,
                            IsProcess: false,
                            AlertMessage: this.global.LanguagePack[this.global.LanguageNo].AlertFailSubmit
                        });
                    } else {
                        if (result.IsSuccess === 1) {
                            _IsRetry = false
                            this.setState({
                                IsSuccess: true,
                                IsRetry: false,
                                IsComplete: false,
                                IsSubmit: 1,
                                IsAlertError: false,
                                IsDelay: true,
                                OrderNo: result.OrderNo,
                                AlertMessage: 'Success',
                            });
                        }
                    }
                });
        } catch (error) {
            //   console.log(error)
            alert(error);
        }
    };



    _StartTimer() {

        if (IsTimerStarted === true) {
            return null
        }


        //  console.log('Start Timer')
        this.timer = setInterval(() => {
            //      console.log('Count', this.state.count)
            if (this.state.count === 2) {
                this.stoptimer();
            }

            let _count = this.state.count + 1

            this.setState({ count: _count, IsBack: false, IsTimerStarted: true, IsProcess: true });
        }, 1000);
    }
    stoptimer() {

        //  console.log('Stop timer')
        clearInterval(this.timer);
        Save_Value("RESET");
        IsTimerStarted = false
        this.setState({ IsDelay: false, IsBack: true, IsFinalExit: false, IsProcess: false, IsComplete: true })
    }

    _RenderDelay = () => {
        if (this.state.IsDelay === true) {
            this._StartTimer()
            IsTimerStarted = true
        }
    }

    _RenderComplete = () => {
        return (
            <Ion.IonModal isOpen={this.state.IsComplete}
                backdropDismiss={false}>

                <Ion.IonCard>
                    <img src={interface_logo} alt="" className="center" />
                    <br />
                    <Ion.IonCardContent>
                        <Ion.IonCardTitle className="ion-text-center">
                            {this.global.LanguagePack[this.global.LanguageNo].LabelThankyou}{" "}
                            <Ion.IonText color="danger">{this.global.MemberName}</Ion.IonText>
                            <br />
                            <br />
                        </Ion.IonCardTitle>
                        <Ion.IonCardTitle className="ion-text-center">
                            {this.state.DisplayTitle}
                        </Ion.IonCardTitle>
                        <br />
                        <Ion.IonCardSubtitle className="ion-text-center">
                            {this.global.LanguagePack[this.global.LanguageNo].LabelOrderNo}{" "}
                            <Ion.IonText color="danger"> {this.state.OrderNo}</Ion.IonText>
                        </Ion.IonCardSubtitle>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <Ion.IonButton
                            color={this.global.Color}
                            expand="full"
                            size="large"
                            onClick={() => this._ResetGlobalState()}>
                            {this.global.LanguagePack[this.global.LanguageNo].LabelContinue}
                        </Ion.IonButton>
                    </Ion.IonCardContent>
                </Ion.IonCard>
            </Ion.IonModal>
        );

    };


    _RenderMessage = () => {
        return (
            <Ion.IonAlert backdropDismiss={false}
                isOpen={this.state.IsProcess}
                onDidDismiss={() => this.setState({ IsProcess: false })}
                message={this.global.LanguagePack[this.global.LanguageNo].AlertSubmit}
            />
        )
    }


    _RenderDelayMessage = () => {
        return (
            <Ion.IonLoading
                isOpen={this.state.IsDelay}
                onDidDismiss={() => this.setState({ IsDelay: false, IsProcess: false, IsComplete: true })}
                message={this.global.LanguagePack[this.global.LanguageNo].AlertSubmit}
                duration={5000}
            />
        )
    }


    _RenderFail = () => {

        return (
            <Ion.IonAlert backdropDismiss={false}
                isOpen={this.state.IsAlertError}
                onDidDismiss={() => this.setState({ IsProcess: false, IsErrorBack: true, IsDelay: false, IsComplete: false })}
                message={this.global.LanguagePack[this.global.LanguageNo].AlertFailSubmit}
                header={this.global.LanguagePack[this.global.LanguageNo].AlertTitle}
                buttons={["OK"]}
            />
        )
    }


    /*     _RenderFail = () => {
    
            return (
                <Ion.IonAlert backdropDismiss={false}
                    isOpen={this.state.IsAlertError}               
                    message={'Error while submitting, Please anykey to retry ...' + _SubmitCount}
                    buttons={[
                        {
                            text: 'Ok',
                            handler: () => this._RenderRetry()
                        }]
                    }
    
                />
            )
        }
    
        _RenderRetry = () => {
            console.log('Retry...')
            _IsRetry = true
            this._FetchSavePaymentData()
            this.forceUpdate()
            //  this.setState({ IsRetry: true, IsProcess: false })
        }
     */
    componentDidMount() {
        this._FetchSavePaymentData();
    }


    _ResetGlobalState = () => {
        Save_Value("RESET");
        this.setState({ IsBack: true, IsComplete: true, IsFinalExit: true });
    };

    render() {

      //     console.log('state', this.state)

        if (this.state.IsErrorBack === true) {
            return <PosMain />;
        }


        if (this.state.IsFinalExit === true) {
            return <PosStart />;
        }

        if (this.state.IsRetry === true) {
            //this._FetchSavePaymentData()
        }

        return (
            <div>
                {  this._RenderMessage()}
                {this._RenderDelayMessage()}
                {this._RenderComplete()}
                {this._RenderFail()}
            </div>
        )
    }
}

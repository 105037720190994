


export function StoreParameterValue(StoreName, Value) {


    switch (StoreName) {
        case 'BASEDURL':
            localStorage.setItem('BasedUrl', Value);
            break;
        case 'OUTLETCODE':
            localStorage.setItem('OutletCode', Value);
            break;
        case 'METHOD':
            localStorage.setItem('Method', Value);
            break;
        case 'LANGUAGE':
            localStorage.setItem('LanguageNo', Value);
            break;
        case 'CLASSTYPE':
            localStorage.setItem('ClassType', Value);
            break;
        case 'CLASSCODE':
            localStorage.setItem('ClassCode', Value);
            break;
        case 'VIEWSTYLE':
            return localStorage.setItem('ViewStyle', Value);
        case 'LIVEDATA':
            localStorage.setItem('LiveData', JSON.stringify(Value));
            break;
        case 'ITEMDATA':
            localStorage.setItem('ItemData', JSON.stringify(Value));
            break;
        case 'TOTALITEMS':
            localStorage.setItem('TotalItems', Value);
            break;
        default:
            break;
    }
}

export function GetParameterValue(StoreName) {


    switch (StoreName) {
        case 'BASEDURL':
            return localStorage.getItem('BasedUrl');
        case 'OUTLETCODE':
            return localStorage.getItem('OutletCode');
        case 'METHOD':
            return localStorage.getItem('Method');
        case 'LANGUAGE':
            return localStorage.getItem('LanguageNo');
        case 'CLASSTYPE':
            return localStorage.getItem('ClassType');
        case 'CLASSCODE':
            return localStorage.getItem('ClassCode');
        case 'VIEWSTYLE':
            return localStorage.getItem('ViewStyle');
        case 'TOTALITEMS':
            return localStorage.getItem('TotalItems');
        case 'LIVEDATA':
            return JSON.parse(localStorage.getItem('LiveData'));
        case 'ITEMDATA':
            return JSON.parse(localStorage.getItem('ItemData'));

        default:
            break;
    }
}




export function ConvertFormat(e) {
    return e.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export async function FetchStoreData(zData) {


    try {

        let url = localStorage.getItem('BasedUrl') + 'Trans/StoreData?zOutletCode=' + localStorage.getItem('OutletCode')
        let NewData
        let NewArray = []

        NewArray.push(zData)
        NewData = JSON.stringify(NewArray)
        console.log('Fetch Store Data')
        console.log(url)
        console.log(NewData)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        //var raw = "[{'UserID':'" + zId + "','Password':'" + zPass + "'}]";

        //   console.log(raw)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: NewData,
            redirect: 'follow'
        };

        await fetch(url, requestOptions)
            .then(response => response.json())


            .then(result => {

                if (result.IsSuccess === 0) {
                    this.setState({
                        ShowAlert: true,
                        AlertMessage: result.ErrorMessage
                    })
                    //  alert(result.ErrorMessage)
                }
                else {
                    console.log(result)
                    console.log(result.IsSuccess)
                    console.log(result.OrderNo)

                    let ReturnResult = {
                        OrderNo: result.OrderNo
                    }
                    if (result.IsSuccess === 1) {

                        console.log('done done')
                        console.log(ReturnResult)
                        //return ReturnResult
                        //   return 'aaaaaa'
                    }

                    console.log('done done')
                    console.log(ReturnResult)

                    return ReturnResult
                }
            })
    } catch (error) {
        console.log(error)
    }

}



export function Color(e) {
    switch (e) {
        case '0':
            return 'primary'
        case '1':
            return 'secondary'
        case '2':
            return 'tertiary'
        case '3':
            return 'success'
        case '4':
            return 'warning'
        case '5':
            return 'danger'
        case '6':
            return 'light'
        case '7':
            return 'medium'
        case '8':
            return 'dark'
        default:
            break;
    }
}


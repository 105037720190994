import React, { Component } from "reactn";
import * as Ion from "@ionic/react";
import "./StyleCard.css";
import { language } from "ionicons/icons";
import BackImage from "../Image/mainscreen.png";
import { Save_Value } from "../functions/Data";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PosMain from "./PosMain";
import PosOrder from "./PosOrder";
import * as lod from "lodash";
import LandingPage from "./LandingPage";

let heightCarousel = "100vh";
export default class PosStart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      IsReload: false,
      IsLangLoading: false,
      IsLoading: true,
      IsMethod: false,
      IsNew: false,
      IsOrder: false,
      Method: "",
      IsErrorPay: false,
      Data: null,
      IsMethod: false,
      IsDineInPopup: false,
      IsOpen: false,
      IsMyOrder: false,
      IsLang: false,
      LanguageNo: 0,
      Service: null,
      currentCount: 5,
      ImageIndex: 0,
      ImageLoop: this.global.ImageLoop,
      mainscreen: { BackImage },
    };
  }

  componentDidMount() {
    //   console.log('Hello..............')
    Save_Value("RESET");
    //  this._FetchMaster()
    this._FetchServiceData();
  }

  _UpdateLanguageState = (x) => {
     //   console.log('object',x)


    switch (x) {
      case 0:
     //   console.log('pppppppp')
        this.global.ItemMaster.forEach((el) => {
          el.Name = el.NameDef;
        });
        this.global.ClassMaster.forEach((e2) => {
            e2.Name = e2.NameDef;
          });
        break;
      case 1:
     //     console.log('fffffff')
        this.global.ItemMaster.forEach((el) => {
          el.Name = el.Name1;
        });
        this.global.ClassMaster.forEach((e2) => {
            e2.Name = e2.Name1;
          });
        break;
      default:
        break;
    }

    this.setState({ IsReload: true, IsLang: false, IsLangLoading: true });
    Save_Value("LANGUAGE", x);

    //   this._FetchMaster2();

  


    //  console.log('ddddddd4444',this.global.ItemMaster)
  };

  _FetchMaster2 = async () => {
    //this.global.LiveServiceType

    let _SerCode = localStorage.getItem("DefServiceCode");

    //  console.log('ddddd',_SerCode)

    let _url =
      this.global.BaseUrl +
      "Item/GetMaster?zMasterOutletCode=" +
      this.global.ProfileCode +
      "&zLanguageNo=" +
      this.global.LanguageNo +
      "&zServiceCode=" +
      _SerCode +
      "&zTokenID=" +
      this.global.Token +
      "&zTableCode=" +
      this.global.TableCode;

    //   console.log('Fetch Master')
    await fetch(_url)
      .then((response) => response.json())
      .then((result) => {
        //     console.log('Hello', result)
        this.setState({ IsLoading: true, IsLangLoading: false });
        this.setGlobal({ ItemMaster: result.ItemMaster });
      });
  };
  _RenderFAB = () => {
    return (
      <Ion.IonFab vertical="top" horizontal="end">
        <Ion.IonFabButton
          color="light"
          onClick={() => this.setState({ IsLang: true })}
        >
          <Ion.IonIcon icon={language} />
        </Ion.IonFabButton>
      </Ion.IonFab>
    );
  };

  _StartSelectService = () => {
    if (this.global.ServiceCode === "T") {
      this._UpdateClickService("T", "TAKE AWAY");
    } else {
      if (this.state.Data === null || this.state.Data.length === 0) {
        this._UpdateClickService("D", "DINE IN");
      } else {
        this._FetchServiceData();
        this.setState({ IsMethod: true });
      }
    }
  };

  _FetchServiceData = async (x) => {
    //     console.log(this.global)

    try {
      let url =
        this.global.BaseUrl +
        "Service/GetServiceType?zOutletCode=" +
        this.global.ProfileCode +
        "&zServiceGroup=" +
        this.global.ServiceCode +
        "&zLanguageNo=" +
        this.global.LanguageNo;

      //    console.log(url);

      await fetch(url)
        .then((response) => response.json())

        .then((result) => {
          if (result.IsSuccess === 0) {
            this.setState({
              Data: null,
              IsMethod: false,
              IsShowAlert: true,
              //   IsLoading: false,
              ErrorMessage: result.ErrorMessage,
            });
          } else {
            this.setState({
              Data: result,
              IsShowAlert: false,
              //   IsLoading: false,
              ErrorMessage: result.ErrorMessage,
            });
          }
        });
    } catch (error) {
      this.setState({ Data: null, IsShowAlert: true, IsLoading: false });
    }
  };

  _LoopImageSlides = () => {
    let _ImageName = BackImage;

    if (this.global.ImageLoop === undefined) {
      //   return null
    }

    if (this.global.ImageLoop[0] === undefined) {
      //   return null
    } else {
      _ImageName = this.global.ImageLoop[0].ImageName;
    }

    //  console.log(this.global.ImageLoop[0])

    return (
      <div className="ImageContainer">
        <img
          style={{
            width: "100%",
            height: heightCarousel,
            textAlign: "center",
            "object-fit": "cover",
          }}
          src={_ImageName}
          alt=""
        />
        <div className="ImageTextMain">{this._RenderButton()}</div>
        <Ion.IonLabel className="ion-text-center">
          <Ion.IonText className="ion-text-wrap">
            <div className="ImageTextTop">
              <h6>{this.global.OutletName}</h6>
            </div>
          </Ion.IonText>
        </Ion.IonLabel>
      </div>
    );
  };

  _RenderLanguage = () => {
    return (
      <Ion.IonPopover
        isOpen={this.state.IsLang}
        onDidDismiss={(e) => this.setState({ IsLang: false })}
      >
        <Ion.IonList>
          <Ion.IonListHeader>
            <Ion.IonLabel className="ion-text-center">
              {
                this.global.LanguagePack[this.global.LanguageNo]
                  .ButtonSelectLanguage
              }
            </Ion.IonLabel>
          </Ion.IonListHeader>
          <br />
          <Ion.IonItem lines="none">
            <Ion.IonLabel>
              <Ion.IonButton
                color={this.global.Color}
                size="large"
                expand="full"
                onClick={() => this._UpdateLanguageState(0)}
              >
                English
              </Ion.IonButton>
            </Ion.IonLabel>
          </Ion.IonItem>

          <Ion.IonItem lines="none">
            <Ion.IonLabel>
              <Ion.IonButton
                color={this.global.Color}
                size="large"
                expand="full"
                onClick={() => this._UpdateLanguageState(1)}
              >
                中文
              </Ion.IonButton>
            </Ion.IonLabel>
          </Ion.IonItem>
        </Ion.IonList>
      </Ion.IonPopover>
    );
  };

  _RenderButton = () => {
    let _TableName = this.global.TableName;

    //console.log('This global',this.global)

    //    let _TableName = 'Take Away Ref No. [' + this.global.TableName + ']'
    if (this.global.ServiceCode !== "T") {
      _TableName =
        this.global.LanguagePack[this.global.LanguageNo].ButtonMyTableCaption +
        this.global.TableName;
    } else {
      _TableName = "Take Away Ref No. [" + this.global.TableCode + "]";
    }

    return (
      <>
        <Ion.IonLabel className="ion-text-center">
          <Ion.IonText className="ion-text-wrap">
            <h6> {_TableName} </h6>
          </Ion.IonText>
        </Ion.IonLabel>
        <Ion.IonGrid>
          <Ion.IonRow>
            <Ion.IonCol
              size-lg="6"
              size-xl="6"
              size-md="6"
              size-sm="12"
              size="12"
            >
              <Ion.IonButton
                color={this.global.Color}
                expand="block"
                size="large"
                onClick={() => this._StartSelectService()}
              >
                <Ion.IonText>
                  {
                    this.global.LanguagePack[this.global.LanguageNo]
                      .ButtonOrderCaption
                  }
                </Ion.IonText>
              </Ion.IonButton>
            </Ion.IonCol>
            <Ion.IonCol
              size-lg="6"
              size-xl="6"
              size-md="6"
              size-sm="12"
              size="12"
            >
              <Ion.IonButton
                color={this.global.Color}
                expand="block"
                size="large"
                onClick={() => this.setState({ IsOrder: true })}
              >
                {
                  this.global.LanguagePack[this.global.LanguageNo]
                    .ButtonMyHistoryCaption
                }
              </Ion.IonButton>
            </Ion.IonCol>
          </Ion.IonRow>
        </Ion.IonGrid>
      </>
    );
  };

  _UpdateClickService = (x, y) => {
    //  console.log("Service===>", y);
    //  this.setGlobal({ LiveServiceType: x, ServiceCode: x })

    lod.set(this.global.DefaultAppInfo, "SelectedServiceCode", x);
    lod.set(this.global.DefaultAppInfo, "SelectedServiceName", y);

    //  this.setGlobal({ Title: x.Name })

    let _Title = "";
    _Title = this.global.TableName + " (" + y + ")";

    console.log("Title", _Title);
    this.setState({ IsNew: true, Method: x, IsMethod: false });
    this.setGlobal({
      IsConfirmOrderExit: false,
      LiveReturnMode: "",
      PreSignMenu: "STARTMAIN",
      LiveServiceType: x,
      LiveServiceTypeName: y,
      Title: _Title,
    });

    let _SerCode = this.global.ServiceCode;
    if (this.global.LiveServiceType === "T") {
      _SerCode = "T";
    }

    //  this._FetchData(_SerCode);
  };

  _FetchMaster = async () => {
    // this.global.LiveServiceType

    let _SerCode = localStorage.getItem("DefServiceCode");

    let _url =
      this.global.BaseUrl +
      "Item/GetMaster?zMasterOutletCode=" +
      this.global.ProfileCode +
      "&zLanguageNo=" +
      this.global.LanguageNo +
      "&zServiceCode=" +
      _SerCode +
      "&zTokenID=" +
      this.global.Token +
      "&zTableCode=" +
      this.global.TableCode;

    //     console.log('Fetch Master')
    await fetch(_url)
      .then((response) => response.json())
      .then((result) => {
        //       console.log('Hello', result)
        this.setState({ IsLoading: false });
        this.setGlobal({
          ItemMaster: result.ItemMaster,
          ClassMaster: result.ClassMaster,
        });
      });
  };

  _RenderPopupService = () => {
    //  console.log("Pop Service");
    if (this.state.Data === null) {
      return null;
    }

    if (this.state.ServiceCode === "T") {
      let x = "T";
      let y = "Take Away";
      lod.set(this.global.DefaultAppInfo, "SelectedServiceCode", "T");
      lod.set(this.global.DefaultAppInfo, "SelectedServiceName", "Take Away");

      let _Title = "";
      _Title = "Take Away";

      console.log("Title", _Title);
      this.setState({ IsNew: true, Method: x, IsMethod: false });
      this.setGlobal({
        IsConfirmOrderExit: false,
        LiveReturnMode: "",
        PreSignMenu: "STARTMAIN",
        LiveServiceType: x,
        LiveServiceTypeName: y,
        Title: _Title,
      });

      return null;
    }

    //  console.log("Service", this.state.Data);

    return (
      <Ion.IonModal isOpen={this.state.IsMethod} backdropDismiss={false}>
        <Ion.IonList>
          <Ion.IonListHeader>
            <Ion.IonLabel className="ion-text-center">
              {this.global.OutletName}
              <br />

              {
                this.global.LanguagePack[this.global.LanguageNo]
                  .ButtonOrderingMethod
              }
            </Ion.IonLabel>
          </Ion.IonListHeader>
          <br />

          {this.state.Data.map((item) => (
            <Ion.IonItem lines="none">
              <Ion.IonLabel>
                <Ion.IonButton
                  shape="round"
                  color={this.global.Color}
                  size="large"
                  expand="full"
                  onClick={() => this._UpdateClickService(item.Code, item.Name)}
                >
                  {item.Name}
                </Ion.IonButton>
              </Ion.IonLabel>
            </Ion.IonItem>
          ))}
        </Ion.IonList>
        <Ion.IonButton
          shape="full"
          color={this.global.Color}
          expand="full"
          onClick={() => this.setState({ IsMethod: false })}
        >
          {this.global.LanguagePack[this.global.LanguageNo].ButtonCancelCaption}
        </Ion.IonButton>
      </Ion.IonModal>
    );
  };

  _RenderMessage = () => {
    console.log(
      "object",
      this.state.IsLoading,
      this.global.ClassMaster,
      this.global.ItemMaster
    );

    return (
      <Ion.IonAlert
        backdropDismiss={false}
        isOpen={this.state.IsLoading}
        onDidDismiss={() => this.setState({ IsLoading: false })}
        message={"Please wait while loading..."}
      />
    );
  };

  render() {
//    console.log("Global=====", this.global);

    if (this.state.IsNew === true) {
      //   console.log('Class Master', this.global.ClassMaster)
      return <PosMain />;
    }

    if (this.state.IsReload === true) {
      //   console.log('Class Master', this.global.ClassMaster)
      //   return <LandingPage />;
    }

    if (this.state.IsOrder === true) {
      return <PosOrder />;
    }

    return (
      <div>
        <Ion.IonApp>
          <Ion.IonContent fullscreen>
            {this._LoopImageSlides()}
            {this._RenderFAB()}
            {this._RenderLanguage()}
            {this._RenderPopupService()}
          </Ion.IonContent>
        </Ion.IonApp>
      </div>
    );
  }
}

import React, { Component } from "reactn";
import * as Ion from "@ionic/react";
import { arrowBack, refreshCircle, chevronBack } from "ionicons/icons";
import "./StyleCard.css";
import { ConvertFormat } from "../functions/General";
import PosMain from "./PosMain";
import PosStart from "./PosStart";
import Message from "./Message";

let _textSize = {
    'font-size': '14px'
}

let _lineTextFont = {
    'font-size': '14px',
    'color': 'Dark'
}
let _lineFont = {
    'font-size': '14px',
    'color': 'Gray'
}

let _lineBoldFont = {
    'font-size': '14px',
    'color': 'Red',
    'font-weight': 'bold'
}

export default class PosOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            IsDelay: true,
            IsLoading: true,
            IsNotFound: false,
            Data: null,
            IsBack: false,
            IsConfirm: false,
            IsRefresh: false,
            count: 0
        };



    }

    _ShowCondimentText = (x) => {
        if (x === "") {
            return null;
        }

        return (
            <p>
                <Ion.IonText color="danger" className="ion-text-wrap">
                    {x}
                </Ion.IonText>
            </p>
        );
    };


    _ShowRemarkText = (x) => {
        if (x === "") {
            return null;
        }

        return (
            <p>
                <Ion.IonText color="danger" className="ion-text-wrap">
                    {x}
                </Ion.IonText>
            </p>
        );
    };
    _ShowModifierText = (x) => {
        let data = this.global.LiveData[x].ModifierData;

        //  console.log("ShowModifier", data);
        if (data === undefined) {
            return null;
        }
        if (data === null) {
            return null;
        }

        return (
            <Ion.IonText>
                <Ion.IonList>
                    {data.map((c, index) => (
                        <div>
                            <span className="MyDisplayLeft">{c.MenuName}</span>
                            <span className="MyDisplayRight">
                                {" "}
                                {ConvertFormat(c.SubTotal)}
                            </span>
                            <br />
                        </div>
                    ))}
                </Ion.IonList>
            </Ion.IonText>
        );
    };

    _EmptyCart = () => {
        if (this.global.LiveData === null) {
            return (
                <Ion.IonItem>
                    <Ion.IonLabel>
                        <Ion.IonText className="ion-text-center">Empty Cart</Ion.IonText>
                    </Ion.IonLabel>
                </Ion.IonItem>
            );
        }
    };


    _FetchBills = async (X) => {
        try {

            let _url =
                this.global.BaseUrl +
                "Trans/GetOrderBills?zOrderOutletCode=" +
                this.global.OutletCode +
                "&zTokenID=" +
                this.global.Token + '&zTableCode=' + this.global.TableCode


            //    console.log("bill", _url);
            await fetch(_url)
                .then((response) => response.json())
                .then((result) => {
                    //         console.log('result', result)
                    if (result.length === 0) {
                        this.setState({ IsDelay: false, IsRefresh: false, IsNotFound: true, IsLoading: false, Data: [] })
                    }
                    else {
                        this.setState({ IsBack: false, IsDelay: false, IsRefresh: false, IsNotFound: false, Data: result[0], IsLoading: false });

                    }

                });
        } catch (error) { }
    };

    _Loading = () => {
        return (
            <Ion.IonAlert
                isOpen={this.state.IsLoading}
                message={this.global.LanguagePack[this.global.LanguageNo].AlertWait}
            />
        )
    }

    _AlertNotFound = () => {
        return (
            <Ion.IonAlert
                isOpen={this.state.IsNotFound}
                onDidDismiss={() => this.setState({ IsBack: true })}
                message={this.global.LanguagePack[this.global.LanguageNo].AlertNoMessage}
                header={this.global.LanguagePack[this.global.LanguageNo].AlertTitle}
                buttons={["OK"]}
            />
        )
    }

    componentDidMount() {

        this._StartTimer();
    }




    _ShowFooterTotal = (x) => {

        //    console.log(x)


        let data = x
        return (
            <Ion.IonList>
                <Ion.IonItem style={_lineFont} color={this.global.Color}>
                    <Ion.IonLabel>
                        {this.global.LanguagePack[this.global.LanguageNo].LabelInformation}
                    </Ion.IonLabel>
                </Ion.IonItem>
                <Ion.IonItem style={_lineFont} lines="full">
                    <Ion.IonText slot="start">
                        {this.global.LanguagePack[this.global.LanguageNo].LabelSubtotal}
                    </Ion.IonText>
                    <Ion.IonText slot="end">
                        {ConvertFormat(data.Subtotal)}
                    </Ion.IonText>
                </Ion.IonItem>
                <Ion.IonItem style={_lineFont} lines="full">
                    <Ion.IonLabel>
                        <span>
                            {this.global.LanguagePack[this.global.LanguageNo].LabelDiscount}
                        </span>
                        <span className="MyDisplayRight2">
                            {ConvertFormat(data.LineDiscAmt)}
                        </span>
                        <br />
                        <span>
                            {this.global.LanguagePack[this.global.LanguageNo].LabelSvc}
                        </span>
                        <span className="MyDisplayRight2">
                            {ConvertFormat(data.SvcAmt)}
                        </span>
                        <br />
                        <span>
                            {this.global.LanguagePack[this.global.LanguageNo].LabelTax}
                        </span>
                        <span className="MyDisplayRight2">
                            {ConvertFormat(data.TaxAmt)}
                        </span>
                        <br />
                        <span>
                            {this.global.LanguagePack[this.global.LanguageNo].LabelAdj}
                        </span>
                        <span className="MyDisplayRight2">
                            {ConvertFormat(data.Adj)}
                        </span>
                        <br />
                    </Ion.IonLabel>
                </Ion.IonItem>
                <Ion.IonItem style={_lineFont} lines="none">
                    <Ion.IonText color="danger" slot="start">
                        {this.global.LanguagePack[this.global.LanguageNo].LabelDue}
                    </Ion.IonText>
                    <Ion.IonText color="danger" slot="end">
                        {ConvertFormat(data.Rounded)}
                    </Ion.IonText>
                </Ion.IonItem>
                <Ion.IonItem>
                    <Ion.IonLabel style={_lineFont} className="ion-text-center">
                        {this.global.LanguagePack[this.global.LanguageNo].LabelLastUpdate}  {data.LastSyncTime}
                    </Ion.IonLabel>
                </Ion.IonItem>
            </Ion.IonList>
        );
    };



    _RefreshBills = () => {

        if (this.state.IsRefresh === false) {
            return null
        }


        this._FetchBills()

    }

    _ShowHeader = (x) => {
        let data = x

        if (this.global.ServiceCode === 'D') {
            return (
                <Ion.IonItem lines='none'>
                    <Ion.IonText color={this.global.Color} slot="start">
                        {this.global.LanguagePack[this.global.LanguageNo].ButtonMyTableCaption}
                    </Ion.IonText>
                    <Ion.IonText color={this.global.Color} slot="end">
                        {data.TableName}
                    </Ion.IonText>
                </Ion.IonItem>
            )
        }

        else {

            return (
                <>
                    <Ion.IonItem lines='none'>
                        <Ion.IonText color={this.global.Color} slot="start">
                            Take Away
                        </Ion.IonText>
                        <Ion.IonText color={this.global.Color} slot="end">
                            {data.TableName}
                        </Ion.IonText>
                    </Ion.IonItem>
                </>
            );
        }

    };




    _DelayLoading = () => {
        return (
            setTimeout(() => console.log('Starting action....'), 5000)
        )
    }



    _StartTimer() {

        //   console.log('Start')

        this.timer = setInterval(() => {

            if (this.state.count === 2) {
                this.stoptimer();
            }

            let _count = this.state.count + 1
            let _Mess = "Please wait while processing..."

            this.setState({ count: _count, Message: _Mess });
        }, 1000);
    }

    stoptimer() {

        clearInterval(this.timer);
        this.setState({ IsDelay: false, IsBack: false })
        this._FetchBills()

    }


    render() {
   //     console.log(this.state.Data)


        if (this.state.IsDelay === true) {

            return (
                <Ion.IonAlert
                    isOpen={this.state.IsDelay}
                    message={this.global.LanguagePack[this.global.LanguageNo].AlertWait}
                />
            )

            //   this._DelayLoading()
        }


        //    console.log('Yes',this.state.Data.length)

        if (this.state.Data === null) {

            return null

        }


        if (this.state.Data.length === 0) {
            return (
                <Message />
            )
        }

        //  
        let _Data1 = this.state.Data


        //   console.log('1')


        if (_Data1 === null) {
            return null
        }

        if (_Data1[0] === null) {
            return (
                <Ion.IonAlert
                    isOpen={true}
                    onDidDismiss={() => this.setState({ IsBack: true })}
                    message={this.global.LanguagePack[this.global.LanguageNo].AlertNoMessage}
                    header={this.global.LanguagePack[this.global.LanguageNo].AlertTitle}
                    buttons={["OK"]}
                />

            )
        }

        //  console.log('2')

        //console.log(this.state)

        let _Item = _Data1.items

        if (this.state.IsBack === true) {
            return <PosStart />
        }

        if (this.state.IsLoading === true) {
        //    console.log('Loading...')
            return (

                <Ion.IonAlert
                    isOpen={this.state.IsLoading}
                    onDidDismiss={() => this.setState({ IsBack: true })}
                    message={this.global.LanguagePack[this.global.LanguageNo].AlertWait}

                />
            );
        }
        if (this.state.IsNotFound === true) {
            return (
                <Ion.IonAlert
                    isOpen={this.state.IsNotFound}
                    onDidDismiss={() => this.setState({ IsBack: true })}
                    message={this.global.LanguagePack[this.global.LanguageNo].AlertNoMessage}
                    header={this.global.LanguagePack[this.global.LanguageNo].AlertTitle}
                    buttons={["OK"]}
                />
            );
        }

        //   console.log('3')

        return (
            <Ion.IonApp>
                <Ion.IonHeader>
                    <Ion.IonToolbar color={this.global.Color}>
                        <Ion.IonButtons slot="start">
                            <Ion.IonButton onClick={() => this.setState({ IsBack: true })}>
                                <Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
                            </Ion.IonButton>
                        </Ion.IonButtons>
                        <Ion.IonButtons slot="end">
                            <Ion.IonButton onClick={() => this.setState({ IsRefresh: true })}>
                                <Ion.IonIcon icon={refreshCircle}></Ion.IonIcon>
                            </Ion.IonButton>
                        </Ion.IonButtons>
                    </Ion.IonToolbar>
                </Ion.IonHeader>

                <Ion.IonContent>

                    {this._ShowHeader(_Data1)}

                    {this._RefreshBills()}

                    <Ion.IonList>

                        {_Item.map((c, index) => (
                            <Ion.IonItemGroup>
                                <Ion.IonItem lines='full'>
                                    <Ion.IonLabel style={_lineTextFont}>
                                        <span style={_lineFont}>
                                            <h6>{this.global.LanguagePack[this.global.LanguageNo].LabelOrderNoLine} {c.OrderNo} / {c.ServiceCode}  </h6>
                                        </span>
                                        <span>
                                        </span>
                                        <span style={_lineFont}>
                                            <h6>{c.DateCreated}</h6>
                                        </span>
                                        <span>
                                            {c.MenuCode} - {c.MenuName}
                                        </span>
                                        <span className="MyDisplayRight2">
                                            {c.Qty} x {ConvertFormat(c.UnitPrice)}
                                        </span>
                                        <br />
                                        <span>
                                        </span>
                                        <span className="MyDisplayRight2">
                                            {ConvertFormat(c.Amount)}
                                        </span>

                                    </Ion.IonLabel>
                                </Ion.IonItem>

                            </Ion.IonItemGroup>
                        ))}

                    </Ion.IonList>
                    {this._ShowFooterTotal(_Data1)}
                </Ion.IonContent>

            </Ion.IonApp>
        );
    }
}

import { setGlobal, useGlobal, getGlobal } from "reactn";
import * as lod from "lodash";
import moment from "moment";

let _SvcAmount = 0;

export function Save_LiveData(zValue, zLiveData) {
  //console.log('My Global', _DelCharge)

  let data = zLiveData;

  //console.log('Save',zValue)

  // 100% = no tax and service charge
  // 110% = only got service charge
  //116% = got tax and service charge
  // 106% = only got tax
  // 116.6% everything inclusive
  // 106.6% service charge exclusive

  switch (zValue.Action) {
    case "ADD":
      let val = RecLineValue(zValue, zValue.Qty);
      data.push(val);
      break;

    case "EDIT":
      let _data2 = data[zValue.Index];
      //   console.log("Edi-Data", data);

      let zSubtotal = zValue.Qty * _data2.UnitPrice;
      //    let _TaxRate = data[zValue.Index].TaxValue;
      //   let _TaxMethod = data[zValue.Index].TaxMethod;

      let zData = {
        Qty: zValue.Qty,
        UnitPrice: _data2.UnitPrice,
        TaxValue: _data2.TaxValue,
        SvcValue: _data2.SvcValue,
        TaxRate: _data2.TaxValue,
        SvcRate: _data2.SvcValue,
        TaxMethod: _data2.TaxMethod,
        RvcServiceRef: _data2.RvcServiceRef,
        IsTaxSvc: _data2.IsTaxSvc,
      };

      //  console.log('Edi----zData', zData)

      //  console.log('Edit--Data', data[zValue.Index])

      let _TaxValue = ReCalTrans(zData, zValue.Qty);
      let zAmount = zSubtotal + _TaxValue + _SvcAmount;

      if (zValue.Qty === 0) {
        data.splice(zValue.Index, 1);
      } else {
        lod.set(_data2, "Qty", zValue.Qty);
        lod.set(_data2, "SubTotal", zSubtotal);
        lod.set(_data2, "Amount", zAmount);
        lod.set(_data2, "Rounded", zAmount);
        lod.set(_data2, "NettAmt", zAmount);
        lod.set(_data2, "TaxAmt", _TaxValue);
        lod.set(_data2, "SvcAmt", _SvcAmount);
      }

      //  console.log('Modifier -> Data', data)
      //   console.log("Modifier", _data2.ModifierData);

      let p = 0;

      if (_data2.ModifierData !== undefined) {
        if (_data2.ModifierData != null) {
          for (p = 0; p < _data2.ModifierData.length; p++) {
            //     console.log("Modi Data =>", _data2.ModifierData[p]);

            let zSubtotal2 = zValue.Qty * _data2.ModifierData[p].UnitPrice;

            //    console.log("Edit - Modifier - Subtotal", zSubtotal2);

            let _TaxValue2 = ReCalTrans(_data2.ModifierData[p], zValue.Qty);
            let zAmount2 = zSubtotal2 + _TaxValue2 + _SvcAmount;

            //      console.log("Edit - Modifier - Amount", zAmount2);

            lod.set(_data2.ModifierData[p], "Qty", zValue.Qty);
            lod.set(_data2.ModifierData[p], "SubTotal", zSubtotal2);
            lod.set(_data2.ModifierData[p], "Amount", zAmount2);
            lod.set(_data2.ModifierData[p], "Rounded", zAmount2);
            lod.set(_data2.ModifierData[p], "NettAmt", zAmount2);
            lod.set(_data2.ModifierData[p], "TaxAmt", _TaxValue2);
            lod.set(_data2.ModifierData[p], "SvcAmt", _SvcAmount);
          }
        }
      }

      if (_data2.ComboSetData !== undefined) {
        if (_data2.ComboSetData != null) {
          for (p = 0; p < _data2.ComboSetData.length; p++) {
            let zSubtotal2 = 0;

            let _TaxValue2 = 0;
            let zAmount2 = 0;

            //      console.log("Edit - Modifier - Amount", zAmount2);

            lod.set(_data2.ComboSetData[p], "Qty", zValue.Qty);
            lod.set(_data2.ComboSetData[p], "SubTotal", 0);
            lod.set(_data2.ComboSetData[p], "Amount", 0);
            lod.set(_data2.ComboSetData[p], "Rounded", 0);
            lod.set(_data2.ComboSetData[p], "NettAmt", 0);
            lod.set(_data2.ComboSetData[p], "TaxAmt", 0);
            lod.set(_data2.ComboSetData[p], "SvcAmt", 0);
          }
        }
      }

      break;

    case "REMOVE":
      data.splice(zValue.Index, 1);
      break;

    default:
      break;
  }

  let sum = 0;
  let modsum = 0;
  let modSvc = 0;
  let modTax = 0;
  let modNett = 0;
  let TaxSum = 0;
  let SvcSum = 0;
  let Nett = 0;
  var i;
  var k;
  let t = 0;

  //.log("data", data);

  for (i = 0; i < data.length; i++) {
    sum += data[i].SubTotal;
    TaxSum += data[i].TaxAmt;
    SvcSum += data[i].SvcAmt;
    Nett += data[i].NettAmt;

    t += 1;

    if (data[i].ModifierData != null) {
      for (k = 0; k < data[i].ModifierData.length; k++) {
        modsum += data[i].ModifierData[k].SubTotal;
        modTax += data[i].ModifierData[k].TaxAmt;
        modSvc += data[i].ModifierData[k].SvcAmt;
        modNett += data[i].ModifierData[k].NettAmt;
      }
    }
  }

  let _Gob = getGlobal();
  let _DelCharge = _Gob.LiveDelCharge;
  //console.log('Del', _DelCharge)

  sum = sum + modsum;
  TaxSum = TaxSum + modTax;
  SvcSum = SvcSum + modSvc;
  Nett = Nett + modNett;

  let Rounded = RoundingAdj(Nett);
  let Adj = Rounded - Nett;

  Adj = Math.round(Adj * 100) / 100;

  //console.log("sum info - Rounded", Rounded);
  //console.log("sum info - Nett", Nett);
  // console.log("Adj", Adj);
  let _Type = localStorage.getItem("TaxType");
  if (_Type === "I") {
    sum = sum - (TaxSum + SvcSum);
    Adj = 0;
    Rounded = sum + TaxSum + SvcSum;
  }

  Rounded = Rounded + _DelCharge;

  setGlobal({
    LiveSubtotal: sum,
    LiveTaxValue: TaxSum,
    LiveSvcValue: SvcSum,
    LiveAmount: Nett,
    LiveRounded: Rounded,
    LiveAdj: Adj,
    LiveItemCount: t,
    LiveData: data,
  });
}

export function Save_Value(zAction, zValue) {
  // const [data] = useGlobal("Default");

  switch (zAction) {
    case "VIEWTYPE":
      setGlobal({ ViewType: zValue });
      break;

    case "LANGUAGE":
      setGlobal({ LanguageNo: zValue, LanguagePack: ConvertLanguage(zValue) });
      break;

    case "INIT":
      /*  let _LiveData = {
         LiveData: null,
         CurrentClassCode: "",
         CurrentClassType: "",
         TotalItemCount: 0,
       }; */

      let _date = moment().format("YYYY-MM-DD");
      let _time = moment().format("hh:mm");

      let _DefApp = {
        MobileNo: null,
        CustomerName: null,
        SelectedDate: _date,
        SelectedTime: _time,
        DeliveryAddress: null,
      };

      setGlobal({
        ItemMaster: null,
        ClassMaster: null,
        LiveDelCharge: 0,
        IsConfirmOrderExit: false,
        DefaultAppInfo: _DefApp,
        GoogleApiKey: "AIzaSyBJOkSSqHCxlZv-x4RacrqRDzcKLwmRde8",
        LalamoveApiKey: "f1bec2a3b92e46baa863f89c2c72f481",
        LalamoveSecretKey:
          "MCsCAQACBQCxUI3dAgMBAAECBFZHsDUCAwDczwIDAM2TAgJRxQICHzECAisz",
        Title: "",
        AppType: null,
        WebPaymentInfo: null,
        Mol_Merchant: "SB_SIS",
        Mol_vkey: "ff98baf97cf9f4fc6381314c2ff3fb5b",
        BaseUrl: "",
        BaseMemberUrl: "http://api.vpos.com.my/crm/API/",
        BaseMemberUrlx: "http://localhost:57508/API/",
        OutletCode: "",
        ServiceCode: "",
        TableCode: "",
        TableName: "",
        RvcCode: "",
        ImageLoop: "",
        Token: "",
        zLoginMember: localStorage.getItem("zLoginMember"),
        ProfileCode: "",
        CompanyCode: "",
        CompanyName: "",
        OutletName: "",
        BizDate: "",
        PriceGroupNo: 0,
        LanguageNo: 0,
        LanguagePack: ConvertLanguage(0),
        Color1: localStorage.getItem("DefaultColor"),
        Color: "dark",
        LiveData: [],
        LiveItemCount: 0,
        LiveTaxValue: 0,
        LiveSvcValue: 0,
        LiveLineDiscount: 0,
        LiveAmount: 0,
        LiveAdj: 0,
        LiveRounded: 0,
        LiveSubtotal: 0,
        LiveIsStart: false,
        LiveReturnMode: "",
        LiveOrderNo: "ORD10001",
        LiveIsGroupView: true,
        LiveServiceType: "",
        LiveServiceTypeName: "",
        LivePaymentCode: "",
        LivePaymentName: "",
        LiveRemark: "",
        LiveCity: "",
        LiveStreet1: "",
        LiveStreet2: "",
        LiveStreet3: "",
        LivePostCode: "",
        LiveDeliveryAddress: "",
        LiveCollectOutletCode: "",
        LiveCollectOutletName: "",
        LiveCollectOutletAddress: "",
        LiveSelectedClassType: "",
        LiveTaxType: "E",
        IsView: false,
        SelectedSwiperIndex: 0,
        SelectedClassCode: "",
        SelectedClassType: "",
        SelectedClassDetailsData: null,
        SelectedItemData: null,
        SelectPaymentLinkUrl: null,
        SelectedOutletData: null,
        ViewType: "GRID",
        ViewText: "VIEW ALL",
        MemberCode: localStorage.getItem("v8MemberCode"),
        MemberName: localStorage.getItem("v8MemberName"),
        MemberEmail: "demo@demo.com",
        PreSignMenu: "",
        HistoryBills: [],
        HistoryBillDetails: [],
        IsNoEditQty: false,
      });

      localStorage.setItem("TaxType", "E");
      break;

    case "DEFAULT":
      setGlobal({
        BaseUrl: localStorage.getItem("BasedUrl"),
        //  BaseMemberUrl: localStorage.getItem("BasedMemberUrl"),
        ProfileCode: localStorage.getItem("ProfileCode"),
        ServiceCode: localStorage.getItem("ServiceCode"),
        TableCode: localStorage.getItem("TableCode"),
        Token: localStorage.getItem("Token"),
        ActionType: localStorage.getItem("ActionType"),
        RvcCode: localStorage.getItem("zRvcCode"),
        AppType: localStorage.getItem("zAppType"),
      });

      localStorage.removeItem("BasedMemberUrl");
      localStorage.removeItem("ProfileCode");
      localStorage.removeItem("ServiceCode");
      localStorage.removeItem("TableCode");
      localStorage.removeItem("BasedUrl");
      localStorage.removeItem("Token");
      localStorage.removeItem("ActionType");
      localStorage.removeItem("zRvcCode");
      localStorage.removeItem("zAppType");
      break;

    case "RESET":
      let DefApp = {
        AppType: global.AppType,
        MobileNo: null,
        CustomerName: null,
        SelectedDate: null,
        SelectedTime: null,
        DeliveryAddress: null,
      };

      setGlobal({
        LiveData: [],
        LiveItemCount: 0,
        LiveSubtotal: 0,
        LivePaymentCode: "",
        LiveRemark: "",
        LiveDeliveryAddress: "",
      });
      break;
    default:
      break;
  }
}

function ConvertLanguage(e) {
  let lang = [
    {
      ButtonRemarkCaption: "Remark",
      ButtonMyTableCaption: "My Table No.",
      ButtonOrderCaption: "Start New Order",
      ButtonMyHistoryCaption: "My Order",
      ButtonDeliveryCaption: "DELIVERY",
      ButtonSelfCaption: "SELF COLLECT",
      ButtonDineInCaption: "DINE IN",
      ButtonTakeAwayCaption: "TAKE AWAY",
      ButtonCancelCaption: "CANCEL",
      ButtonSelectLanguage: "Select Language",
      ButtonOrderingMethod: "Choose your ordering method",
      ButtonSelectCategory: "Select Category",
      ButtonSelectCondiment: "Select Condiment",
      ButtonSelectModifier: "Select Modifier",
      ButtonAddCaption: "Add",
      ButtonEditCaption: "EDIT",
      ButtonTotalCaption: "TOTAL",
      ButtonComfirmCaption: "CONFIRM",
      ButtonAddCart: "Add to Cart",
      LabelInformation: "BILL TOTAL",
      LabelDetails: "DETAILS",
      LabelSummary: "SUMMARY",
      LabelSubtotal: "SUBTOTAL",
      LabelTax: "TAX",
      LabelSvc: "SERVICE CHARGE",
      LabelDue: "TOTAL DUE",
      LabelSignIn: "SIGN IN",
      LabelSignup: "SIGN UP",
      LabelPassword: "Password",
      LabelConfirmPassword: "Confirm Password",
      LabelName: "Name",
      LabelMobileNo: "Mobile No.",
      LabelEmail: "Email Address",
      LabelThankyou: "THANK YOU",
      LabelText1: "Your Order already submitted, please wait to be served.",
      LabelText2: "Your Payment already accepted",
      LabelOrderNo: "YOUR ORDER NO.",
      LabelOrderNoLine: "Order No.",
      LabelLastUpdate: "Last Update on : ",
      LabelSelectPickupAddress: "PLEASE PICKUP ADDRESS",
      LabelSelectDeliveryAddress: "PLEASE DELIVERY ADDRESS",
      LabelSelectPaymentMode: "PLEASE SELECT PAYMENT MODE",
      LabelSelectClassification: "SELECT CLASSIFICATION",
      LabelServiceType: "SERVICE TYPE",
      LabelPickupAddress: "PICKUP ADDRESS",
      LabelDeliveryAddress: "DELIVERY ADDRESS",
      LabelTable: "TABLE NO.",
      LabelDiscount: "DISCOUNT",
      LabelAdj: "ROUND ADJ",
      LabelContinue: "CONTINUE",
      AlertNoMessage: "No Order Found !!!",
      AlertWait: "Please wait while loading...",
      AlertSubmit: "Please wait while submitting...",
      AlertUpdate: "Updating in progress...",
      AlertFailSubmit: "Fail to Submit, Please try again later...",
      AlertFailSubmitRetry: "Fail to Submit, Please try again later...",
      AlertConfirmBack:
        "You still have order not submitted, are you sure to abort ?",
      AlertTitle: "Alert !!!",
      AlertQuestionTitle: "Question",
      AlertQuestion: "Confirm to Submit this order ?",
    },
    {
      ButtonRemarkCaption: "备注",
      AlertUpdate: "更新中,请稍候...",
      LabelOrderNoLine: "订单号码.",
      LabelLastUpdate: "最后更新时间 : ",
      AlertConfirmBack: "您的购物车中还有订单，你确定要放弃吗 ?",
      AlertQuestionTitle: "问题",
      AlertQuestion: "确认提交此订单 ?",
      AlertTitle: "注意 !!!",
      AlertNoMessage: "没有订单 !!!",
      AlertWait: "载入中，请稍候...",
      AlertSubmit: "提交中，请稍候......",
      AlertFailSubmit: "提交失败，请稍后再试...",
      ButtonSelectCondiment: "请选择您的调料",
      ButtonSelectModifier: "请选择您的添加品",
      ButtonMyTableCaption: "我的桌子号码是",
      ButtonMyHistoryCaption: "我的订单",
      ButtonOrderCaption: "开始订购",
      ButtonDeliveryCaption: "打包",
      ButtonSelfCaption: "自取",
      ButtonDineInCaption: "堂吃",
      ButtonTakeAwayCaption: "外买",
      ButtonCancelCaption: "取消",
      ButtonSelectLanguage: "选择语言",
      ButtonOrderingMethod: "请选择您的订购方式",
      ButtonSelectCategory: "请选择类别",
      ButtonAddCaption: "加入",
      ButtonEditCaption: "修改",
      ButtonTotalCaption: "合计",
      ButtonComfirmCaption: "确认",
      ButtonAddCart: "确认",
      LabelInformation: "订单详情",
      LabelDetails: "订单详情",
      LabelSummary: "总结",
      LabelSubtotal: "小计",
      LabelTax: "服务税",
      LabelSvc: "服务费",
      LabelDue: "共计",
      LabelSignIn: "登入",
      LabelSignup: "注册",
      LabelPassword: "密码",
      LabelConfirmPassword: "确认密码",
      LabelName: "名字",
      LabelMobileNo: "手机号码",
      LabelEmail: "电邮",
      LabelThankyou: "谢谢",
      LabelText1: "您的订单已提交，请等侍候",
      LabelText2: "您的付款已被接受",
      LabelOrderNo: "您的订单号",
      LabelSelectPickupAddress: "请选择接收的地点",
      LabelSelectDeliveryAddress: "请选择接送餐地点",
      LabelSelectPaymentMode: "请选择付款方式",
      LabelSelectClassification: "请选择食物分组",
      LabelServiceType: "服务类型",
      LabelPickupAddress: "接收地址",
      LabelDeliveryAddress: "送餐地址",
      LabelTable: "台号码",
      LabelDiscount: "折扣",
      LabelAdj: "进位调整",
      LabelContinue: "继续",
    },
  ];

  return lang;
}

export async function FetchData(url) {
  let obj = null;
  await fetch(url)
    .then((response) => response.json())
    .then((result) => {
      obj = result;
    });

  return obj;
}

export function ReCalTrans(zValue, zQty) {
  let _SubTotal = zQty * zValue.UnitPrice;
  let _TaxValue = 0;
  let _SvcValue = 0;
  let _GrossAmt = _SubTotal;
  localStorage.setItem("TaxType", "E");

  // console.log("Svc Qty", zQty);
  // console.log("Svc Unit Price", zValue.UnitPrice);

  //console.log("ReCal", zValue);

  let gb = getGlobal();

  let _Svc = zValue.SvcRate;

  if (gb.LiveServiceType === "T") {
    _Svc = 0;
  }

  if (_Svc !== 0) {
    _SvcValue = (zValue.SvcRate / 100) * _SubTotal;

    if (zValue.IsTaxSvc === 1) {
      _GrossAmt = _GrossAmt + _SvcValue;
    }

    //console.log("Svc Gross", _GrossAmt);
    //  console.log("Svc SvcRate", zValue.SvcRate);
    // console.log("Svc Value", _SvcValue);
  }

  if (zValue.TaxRate !== 0) {
    if (zValue.TaxMethod === "E") {
      localStorage.setItem("TaxType", "E");
      _TaxValue = (zValue.TaxRate / 100) * _GrossAmt;
    } else {
      let _GrossCalAmt = 0;
      _GrossCalAmt = (_SubTotal * 100) / (zValue.TaxRate + 100);
      _TaxValue = _SubTotal - _GrossCalAmt;

      let _Type = localStorage.getItem("TaxType");
      if (_Type !== "I") {
        localStorage.setItem("TaxType", "I");
      }
    }
  }

  // console.log("SubTotal", _SubTotal);
  //  console.log("TaxRate", zValue.TaxRate);
  //  console.log("A", _SubTotal * 100);
  // console.log("B", zValue.TaxRate + 100);
  //  console.log("TaxValue", _TaxValue);

  //  console.log("Svc", _SvcValue);
  _SvcAmount = _SvcValue;
  return _TaxValue;
}

export function RoundingAdj(zAmount) {
  let _TempAmt = parseFloat(zAmount);

  if (_TempAmt < 0) {
    _TempAmt = Math.abs(zAmount);
  }

  let costInCents = _TempAmt * 100;

  if (costInCents === 0) {
    return 0;
  }

  let roundedCostInCents = costInCents - ((costInCents - 3) % 5) + 2;
  let roundedCostInDollars = roundedCostInCents / 100;

  if (zAmount < 0) {
    roundedCostInDollars = 0 - roundedCostInDollars;
  }
  return parseFloat(roundedCostInDollars);
}

export function RecLineValue(zValue, zType) {
  // console.log("RecLine", zValue);
  let _SubTotal = zValue.Qty * zValue.UnitPrice;
  let _TaxValue = ReCalTrans(zValue, zValue.Qty);

  if (zType === "M") {
  }

  let val = {
    MenuCode: zValue.MenuCode,
    MenuName: zValue.MenuName,
    Qty: zValue.Qty,
    UnitPrice: zValue.UnitPrice,
    SubTotal: _SubTotal,
    TaxValue: zValue.TaxValue,
    SvcValue: zValue.SvcValue,
    Amount: _SubTotal + (_TaxValue + _SvcAmount),
    Rounded: _SubTotal + (_TaxValue + _SvcAmount),
    Adj: 0,
    NettAmt: _SubTotal + (_TaxValue + _SvcAmount),
    SvcAmt: _SvcAmount,
    TaxAmt: _TaxValue,
    TaxMethod: zValue.TaxMethod,
    Remark: zValue.Remark,
    Condiment: zValue.Condiment,
    ModifierData: zValue.ModifierData,
    ComboSetData: zValue.ComboSetData,
  };

  // console.log("RecLine--->", val);

  return val;
}

export function GetDistance(lat1, lon1, lat2, lon2, unit) {
  /*   let lat1 = this.state.cor1.lat
    let lat2 = this.state.cor2.lat
    let lon1 = this.state.cor1.lng
    let lon2 = this.state.cor2.lng
    let unit = 'K' */

  unit = "K";

  ///  console.log('Location Lat1', lat1)
  // console.log('Location Lon1', lon1)
  //  console.log('Location Lat2', lat2)
  //  console.log('Location Lon2', lon2)

  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == "K") {
      dist = dist * 1.609344;
    }
    if (unit == "N") {
      dist = dist * 0.8684;
    }

    if (dist === NaN) {
      return 0;
    }

    //console.log('total', 0)
    return parseInt(dist);
  }
}

import React, { Component } from "reactn";
import * as Ion from "@ionic/react";
import "./StyleCard.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PosMain from "./PosMain";
import PosOrder from "./PosOrder";
import * as lod from "lodash";
import PosStart from "./PosStart";

export default class LandingPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            IsLoading: true,
        };


    }
    _FetchMaster = async () => {

        //this.global.LiveServiceType

        let _SerCode = localStorage.getItem("DefServiceCode")

        let _url =
            this.global.BaseUrl +
            "Item/GetMaster?zMasterOutletCode=" +
            this.global.ProfileCode +
            "&zLanguageNo=" +
            this.global.LanguageNo + "&zServiceCode=" + _SerCode + "&zTokenID=" + this.global.Token +
            "&zTableCode=" + this.global.TableCode

     //   console.log('Fetch Master')
        await fetch(_url)
            .then((response) => response.json())
            .then((result) => {
           //     console.log('Hello', result)
                this.setState({ IsLoading: false })
                this.setGlobal({ ItemMaster: result.ItemMaster, ClassMaster: result.ClassMaster })
            });
    };


    _RenderMessage = () => {
      //  console.log('object', this.state.IsLoading, this.global.ClassMaster, this.global.ItemMaster)

        return (
            <Ion.IonAlert backdropDismiss={false}
                isOpen={this.state.IsLoading}
                onDidDismiss={() => this.setState({ IsLoading: false })}
                message={'Please wait while loading...'}
            />
        )
    }

    componentDidMount() {
        this._FetchMaster()
    }
    render() {


     //   console.log(this.global)
        if (this.state.IsLoading === false) {
            return <PosStart />
        }


        return (
            <div>
                {this._RenderMessage()}
            </div>
        )
    }
}
